@import url(https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  font-size: 16px;
  background: white;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  z-index: 1;
}

.ngo-box h2{
  height: 30vw;
  font-family: 'Montserrat';
  font-size: 4em;
}
.ngo-box h2:before{
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
  .ngo-box h2 a{
    font-size: 1.2rem;
    color: red;
    position: relative;
    top: -50%;
  }

.App {
  text-align: center;
}



.App-header {
  position: relative;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  top:4em;
}
.heading-background{
  position: fixed;
  opacity: 0.05;
  font-size: 25em;
  left:-0.2em;
  top:-0.5em;
  z-index: -100;
  color:#9352ae;
}
header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
header h1{
font-size: 1em;
font-weight: 400;  
padding: 2em;
margin-right: 50em;
}
nav{
  position:fixed;
  left:50em;
  top:6em;
z-index: 100;
}
nav li{
  display: inline-block;
  cursor: pointer;

}
nav li .link{
  text-decoration: none;
  color: #40E0D0;
  padding:1em;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
li .link:hover{
  opacity: 1;
}
.active{
  color:black;
  opacity: 1;
}

.header-title button{
  background: black;
  border: 0px;
  padding: 1em 3em;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}
.header-title button:hover{
  opacity: 0.8;
}
.header-title button a{
  font-family: 'Open Sans', sans-serif;
  color:white;
  text-decoration: none;
}
.header-title{
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  text-align: left;
  top:2em;
}
.about{
  display: flex;
  flex-direction: row;
}
.about-content{
  position: relative;
  left:15em;
}
.about-content h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.about-content p{
  font-size: 1.3em;
  text-align: left;
  width: 37em;
}
.about img{
  margin-left: 5em;
}
.experience{
  position: relative;
  display: flex;
  flex-direction: column;
  left:15em;
  top:8em;
  align-items: flex-start;
}
.experience .heading{
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.experience .work-content{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 2em;
}

.project img{
width: 30em;  
height: 30em;
}
.contact{
  position: relative;
  top:20em;
  left:15em;
  text-align: left;
}
.contact h1{
    font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.amazing-color{
  color:#9352ae;
  opacity: 1;
}

.contact .email{
  color:black;
  font-size: 2em;
}
.contact li{
  display: inline-block;
  margin-top:0;
  margin-bottom: 0;
  margin-right: 1em;
  margin-left: 0;
}
.contact li a{
  text-decoration: none;
  color:black;
}
.footer{
  position: relative;
  top:2em;
  padding: 1em;
}
.footer a{
  text-decoration: none;
  color:#2c59fb;
}


/* SMARTPHONES PORTRAIT */
@media only screen and (min-device-width: 320px) and (max-device-width : 768px){

  body{
    font-size: 6px;
  }
  header h1{
font-size: 5em;  
padding: 1em;
margin-right: 0em;
}

.heading-background{
  display: none;
}
nav{
  display: none;
}
.header-title{
  font-size: 3em;
  text-align: left;
  top:1em;
  left:0;
  width: calc(100% - 2em);
}
.about{position: relative;
      display: flex;
      top:-30em;
}
.about img{
  display: none
}
.about-content{
  left:2em;
}
.about-content p{
  font-size: 3.5em;
  text-align: left;
  width: 300px;
}

.experience{
  left:2em;
  top:2em;
  align-items: flex-start;
}
.experience .work-content{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5em;
}
.project img{
width: 45em;  
height: 45em;
}

.contact{
  top:15em;
  left:2em;
}
.contact h1{
  font-size: 4em;
}
.contact .email{
  font-size: 3.5em;
}

.contact li{
  display: block;
  padding: 2em;
}
.contact li a{
  font-size: 2.5em;
}
.footer{
  top:4em;
  padding: 1em;
  font-size: 2em;
}


}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {


body{
    font-size: 10px;
  }
header h1{
font-size: 5em;  
padding: 1em;
margin-right: 0em;
}

nav{
  display: none;
}
.header-title{
  font-size: 3.6em;
  text-align: left;
  top:1em;
  left:1em;
}
.about{position: relative;
      display: flex;
      top:-10em;
}
.about img{
  display: none
}
.about-content{
  left:6em;
}
.about-content p{
  font-size: 3.5em;
  text-align: left;
  width: 700px;
}

.experience{
  left:6em;
  top:4em;
  align-items: flex-start;
}
.experience .work-content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
}
.project img{
width: 30em;  
height: 30em;
}

.contact{
  top:15em;
  left:6em;
}
.contact h1{
  font-size: 4em;
}
.contact .email{
  font-size: 3.5em;
}

.contact li{
  display: block;
  padding: 2em;
}
.contact li a{
  font-size: 2.5em;
}
.footer{
  top:4em;
  padding: 1em;
  font-size: 2em;
}

}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */



body{
    font-size: 20px;
  }
  header h1{
    font-size: 2em;
    margin-right: 25em;
  }


  nav{
  left:60em;
  top:9em;
  z-index: 100;
}
nav li{
   font-size: 1.5em;

}
.header-title{
  font-size: 2em;
  top:1em;
  left:1em;
}
.about{position: relative;
      display: flex;
      top:0;
}
.about-content{
  left:10em;
}
.about-content p{
  font-size: 2em;
  text-align: left;
  width: 25em;
}
.about img{
  margin-left: 10em;
  width: 100%;
}

.experience{
  left:10em;
  top:4em;
  align-items: flex-start;
}
.experience .work-content{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.project img{
width: 32em;  
height: 32em;
}

.contact{
  top:10em;
  left:8em;
}
.contact h1{
  font-size: 8em;
}
.contact .email{
  font-size: 5em;
}

.contact li a{
  font-size: 4em;
  padding: 1em;
}
.footer{
  top:4em;
  padding: 1em;
  font-size: 4em;
}


}



